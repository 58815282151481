import Routes from './routes.js'
import './App.css'


function App() {
  return (
    <div className='App'>
      <Routes/>
    </div>
  );
}


export default App